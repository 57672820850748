import { useState, useEffect, useRef } from 'react'

import Chat, { ChatHandle } from '../../components/Chat'
import { useNavigate } from 'react-router-dom'
import {
  Box,
  Button,
  Container,
  CssBaseline,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
  ThemeProvider,
  Tooltip,
  Typography,
  createTheme,
} from '@mui/material'
import Link from '@mui/material/Link'
import httpClient from '../../util/http-client'
import { SpeechSynthesisProvider } from '../../context/speechSynthesisProvider'

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary">
      {'Copyright © '}
      <Link color="inherit" href="https://alkmaartaalthuis.nl/">
        Alkmaar Taal Thuis
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'} | Voor vragen: <a href="mailto:ivo@alkmaartaalthuis.nl">email</a> |
    </Typography>
  )
}
const defaultTheme = createTheme()

export default function ChatPage() {
  const chatRef = useRef<ChatHandle | null>(null)
  const navigate = useNavigate()
  const [dialogOpen, setDialogOpen] = useState(false)
  const [userName] = useState<string>(sessionStorage.getItem('name') as string)
  const bottomRef = useRef<HTMLDivElement | null>(null)
  const [progress, setProgress] = useState(0)
  const [chatActive, setChatActive] = useState(true)

  const stopChat = () => {
    if (chatRef.current) chatRef.current.onEndSession()
  }

  const logoff = () => {
    stopChat()
    httpClient.post('auth/logoff', {}).then(() => {
      sessionStorage.clear()
      setChatActive(false)
      setTimeout(() => {
        setChatActive(true)
      }, 250)
    })

    navigate('/login')
  }

  useEffect(() => {
    if (!userName) {
      navigate('/login')
    }
  }, [userName])

  const handleDialogClickOpen = () => {
    setDialogOpen(true)
  }

  const handleDialogClose = () => {
    setDialogOpen(false)
  }

  return (
    <ThemeProvider theme={defaultTheme}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
        }}
      >
        <CssBaseline />
        <Container component="main" sx={{ mt: 8, mb: 2 }} maxWidth="sm">
          <div
            style={{
              marginBottom: '2em',
              height: '100%',
              overflow: 'auto',
              scrollBehavior: 'smooth',
            }}
          >
            {chatActive && ( // Render the Chat component only if chatActive is true
              <SpeechSynthesisProvider>
                <Chat
                  ref={chatRef}
                  onUpdate={() => {
                    bottomRef.current?.scrollIntoView({ behavior: 'smooth' })
                  }}
                  logoff={logoff}
                  onProgress={setProgress}
                />
              </SpeechSynthesisProvider>
            )}
            {progress > 0 && (
              <Tooltip title="Je kan met PIM chatten tot het balkje vol is">
                <LinearProgress
                  sx={{ marginTop: '20px', marginLeft: '10px', marginRight: '10px' }}
                  variant="determinate"
                  value={progress}
                />
              </Tooltip>
            )}
            <div ref={bottomRef} />
          </div>
          <Box
            m={1}
            //margin
            display="flex"
            justifyContent="flex-end"
            alignItems="flex-end"
          >
            <Button
              onClick={() => {
                handleDialogClickOpen()
              }}
            >
              Stop chat
            </Button>
          </Box>
        </Container>
        <Dialog fullWidth={true} maxWidth={false} open={dialogOpen} onClose={handleDialogClose}>
          <DialogTitle>Weet je het zeker?</DialogTitle>
          <DialogContent>
            <Box
              noValidate
              component="form"
              sx={{
                display: 'flex',
                flexDirection: 'column',
                m: 'auto',
                width: 'fit-content',
              }}
            >
              <DialogContentText>
                Weet je zeker dat je wilt stoppen? Pim vergeet zo het hele gesprek.
                <br />
                Zo lang je je code niet hebt opgebruikt kan je je code weer gebruiken om verder te chatten tot de code
                op is.
              </DialogContentText>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                handleDialogClose()
              }}
            >
              Nee, ik wil doorgaan met chatten
            </Button>
            <Button
              onClick={() => {
                logoff()
              }}
            >
              Ja, ik wil stoppen
            </Button>
          </DialogActions>
        </Dialog>

        <Box
          component="footer"
          sx={{
            py: 0.5,
            px: 2,
            mt: 'auto',
            backgroundColor: (theme) =>
              theme.palette.mode === 'light' ? theme.palette.grey[200] : theme.palette.grey[800],
          }}
        >
          <Container maxWidth="sm">
            <Typography variant="body1">PIM, by Alkmaar Taal Thuis.</Typography>
            <Copyright />
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  )
}
