import { ChatController, MuiChat } from 'chat-ui-react'
import { useEffect, useState, useImperativeHandle, forwardRef } from 'react'
import { socket } from '../../util/socket'
import { Alert, LinearProgress } from '@mui/material'
import BotMessage from './botMessage'
import delay from 'delay'
import httpClient from '../../util/http-client'
import { AudioPlayer } from '../AudioPlayer'

type ResponseChunk = {
  index: number
  message: string
}

const sortResponseChunks = (responseChunk: ResponseChunk[]) => {
  return responseChunk.sort((a: ResponseChunk, b: ResponseChunk) => a.index - b.index)
}

type SetProgressType = React.Dispatch<React.SetStateAction<number>>

type Props = {
  onUpdate: () => void
  logoff: () => void
  onProgress: SetProgressType
}

export interface ChatHandle {
  onEndSession: () => void
}

const Chat = forwardRef<ChatHandle, Props>(({ onUpdate, logoff, onProgress }, ref) => {
  const [chatCtl] = useState(new ChatController())
  const [responseChunk, setResponseChunk] = useState<ResponseChunk[]>([])
  const [currMessage, setCurrMessage] = useState<string>('')
  const [messageId, setMessageId] = useState<number | undefined>(undefined)
  const [userAnswer, setUserAnswer] = useState<string>('')
  const [isConnected, setIsConnected] = useState(socket.connected)
  const [userName] = useState<string>(sessionStorage.getItem('name') as string)
  const [error, setError] = useState<{ message: string } | undefined>(undefined)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [loadingResponse, setLoadingResponse] = useState<boolean>(true)

  const onEndSession = () => {
    chatCtl.cancelActionRequest()
    socket.disconnect()
  }

  useImperativeHandle(ref, () => ({
    onEndSession,
  }))

  const initSocket = () => {
    if (!userName || isConnected) {
      return
    }
    const token = sessionStorage.getItem('token') as string
    console.info('initSocket')
    socket.auth = { userName, token }
    socket.connect()
    socket.emit('chat:init', ``)
  }

  const onConnect = () => {
    setIsConnected(true)
  }

  const onDisconnect = () => {
    setIsConnected(false)
  }

  const onError = (err: { message: string }) => {
    if (err.message === 'Invalid token') {
      setErrorMessage('Je bent afgemeld. Log opnieuw in met een geldige code.')
    } else {
      setErrorMessage(err.message)
    }

    setError(error)
  }

  const onStartResponse = async () => {
    setLoadingResponse(true)
    const msgId = await chatCtl.addMessage({
      type: 'jsx',
      content: (
        <>
          <BotMessage message={currMessage} loadingResponse={loadingResponse} messageId={messageId} />
        </>
      ),
      self: false,
    })
    setMessageId(msgId)
  }

  const onResponseChunk = (data: string) => {
    const chunk = JSON.parse(data) as ResponseChunk
    setResponseChunk((prev) => [...prev, chunk])
  }

  const onEndResponse = async () => {
    setMessageId(undefined)
    setResponseChunk([])
    onUpdate()

    // capture response:
    const answer = await chatCtl.setActionRequest({ type: 'text' })
    setUserAnswer(answer.value)

    // fetch costs
    httpClient.post('user/costs', {}).then((response) => {
      const costs = response.costPercentage
      onProgress(costs)
    })
    await delay(1000)
    onUpdate()
  }

  useEffect(() => {
    setLoadingResponse(false)

    const fullMessage = sortResponseChunks(responseChunk)
      .map((chunk) => (chunk.message ? chunk.message : ''))
      .join('')
    setCurrMessage(fullMessage)
    if (messageId !== undefined) {
      chatCtl.updateMessage(messageId, {
        type: 'jsx',
        content: (
          <>
            <BotMessage message={currMessage} loadingResponse={loadingResponse} messageId={messageId} />
          </>
        ),
        self: false,
      })
      onUpdate()
    }
  }, [chatCtl, currMessage, messageId, responseChunk])

  useEffect(() => {
    if (userAnswer) {
      socket.emit('chat:message', userAnswer)
      setUserAnswer('')
    }
  }, [userAnswer])

  useEffect(() => {
    socket.on('chat:startResponse', onStartResponse)
    socket.on('chat:responseChunk', onResponseChunk)
    socket.on('chat:endResponse', onEndResponse)
    socket.on('chat:endSession', () => {
      logoff()
    })

    socket.on('connect', onConnect)
    socket.on('disconnect', onDisconnect)
    socket.on('connect_error', onError)
    initSocket()
    return () => {
      socket.off('connect', onConnect)
      socket.off('disconnect', onDisconnect)
      socket.off('chat:startResponse', onStartResponse)
      socket.off('chat:responseChunk', onResponseChunk)
      socket.off('chat:endResponse', onEndResponse)
    }
  }, [])

  // Only one component used for display
  return (
    <>
      {error && (
        <Alert severity="error">
          <strong>Error:</strong> {errorMessage}
        </Alert>
      )}
      {isConnected && (
        <>
          <MuiChat chatController={chatCtl} />
          <AudioPlayer />
        </>
      )}

      {!isConnected && !error && (
        <>
          <LinearProgress color="secondary" />
        </>
      )}
    </>
  )
})

export default Chat
