import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'

type Props = {
  dialogOpen: boolean
  setDialogOpen(open: boolean): void
}

export function InfoDialog({ dialogOpen: open, setDialogOpen }: Props) {
  const handleClose = () => {
    setDialogOpen(false)
  }

  return (
    <Dialog fullWidth={true} maxWidth={false} open={open} onClose={handleClose}>
      <DialogTitle>PIM het digitale taalmaatje</DialogTitle>
      <DialogContent>
        <Box
          noValidate
          component="form"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            m: 'auto',
            width: 'fit-content',
          }}
        >
          <DialogContentText>
            <b>Wat is PIM?</b>
            <br />
            <p>
              PIM is een chatbot, ontwikkeld door{' '}
              <a href="https://alkmaartaalthuis.nl" target="_blank" rel="noreferrer">
                Alkmaar Taal Thuis
              </a>
              . Met PIM kan je oefenen zodat je leert beter Nederlands te spreken en schrijven, en je leert ook wat van
              de Nederlandse 🇳🇱 cultuur 🧀.
              <br />
              PIM is gemaakt met het laatste GPT model van OpenAI. GPT4o is een kunstmatige intelligentie 🤖.
            </p>
            <b>Codes</b>
            <p>
              Om te praten met PIM heb je een code nodig, per code kun je 1 gesprek voeren met PIM. Codes kun je
              aanvragen bij{' '}
              <a href="https://alkmaartaalthuis.nl" target="_blank" rel="noreferrer">
                Alkmaar Taal Thuis
              </a>
              . Je code blijft geldig totdat het tegoed op is, dat kan je zien aan de blauwe balk onderin het
              chat-venster.
            </p>
            <b>PIM praat nu ook!</b>
            <br />
            <p>
              PIM kan je nu ook Nederlandse uitspraak laten horen, bij ieder antwoord van PIM zie je een klein knopje,
              als je daarop drukt hoor je PIM de zin uitspreken.
            </p>
            <br />
            <b>Let op 🐞 !</b>
            <p>
              PIM is nog in ontwikkeling, dus het kan zijn dat er nog foutjes in zitten. Als je een foutje tegenkomt,
              laat het ons weten, je kan mailen naar ivo@alkmaartaalthuis.nl.
            </p>
            <b>Privacy</b>
            <p>PIM slaat geen gesprekken op en bewaart je naam niet. Je privacy is dus veilig bij PIM.</p>
            <b>Wil je ook een PIM?</b>
            <p>Wil je ook een PIM, neem dan contact op met ivo@alkmaartaalthuis.nl</p>
          </DialogContentText>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setDialogOpen(false)
          }}
        >
          sluiten
        </Button>
      </DialogActions>
    </Dialog>
  )
}
